<template>
  <main class="brokers">
    <LandingHeader />

    <section class="top-screen">
      <div class="top-screen__left">
        <h1 class="top-screen__left__title">{{ $t("landing.brokers.top.title") }}</h1>
        <p class="top-screen__left__text">
          {{ $t("landing.brokers.top.text") }}
        </p>
        <v-btn class="top-screen__left__btn landing-btn" color="primary" dark depressed @click="openContactDialog"
          >{{ $t("landing.requestDemo") }}
        </v-btn>
      </div>
      <img alt="Brokers" class="top-screen__right" src="@/assets/landing/brokers-main.jpg" />
      <img alt="Axis logo" class="top-screen__bg-logo" src="@/assets/landing/bg-logo.svg" />
    </section>

    <section class="quality">
      <div class="quality__left">
        <img alt="" class="quality__left__img" src="@/assets/landing/brokers-quality.png" />
      </div>
      <div class="quality__right">
        <h1 class="quality__right__title">
          {{ $t("landing.brokers.quality.title") }}
        </h1>
        <p class="quality__right__text">
          {{ $t("landing.brokers.quality.text") }}
        </p>
        <v-btn class="quality__right__btn landing-btn" color="primary" dark depressed @click="openContactDialog"
          >{{ $t("landing.requestDemo") }}
        </v-btn>
      </div>
    </section>

    <section class="telemedicine">
      <h1 class="telemedicine__title">{{ $t("landing.brokers.telemedicine.title") }}</h1>
      <p class="telemedicine__text">{{ $t("landing.brokers.telemedicine.text") }}</p>
      <div class="telemedicine__feats">
        <div class="telemedicine__feats__item">
          <img alt="" class="telemedicine__feats__item__img" src="@/assets/landing/feel-better.svg" />
          <p class="telemedicine__feats__item__title">
            {{ $t("landing.brokers.telemedicine.feats.feelBetter.title") }}
          </p>
          <p class="telemedicine__feats__item__text">
            {{ $t("landing.brokers.telemedicine.feats.feelBetter.text") }}
          </p>
        </div>
        <div class="telemedicine__feats__item">
          <img alt="" class="telemedicine__feats__item__img" src="@/assets/landing/easy-access.svg" />
          <p class="telemedicine__feats__item__title">
            {{ $t("landing.brokers.telemedicine.feats.easyAccess.title") }}
          </p>
          <p class="telemedicine__feats__item__text">
            {{ $t("landing.brokers.telemedicine.feats.easyAccess.text") }}
          </p>
        </div>
        <div class="telemedicine__feats__item">
          <img alt="" class="telemedicine__feats__item__img" src="@/assets/landing/architecture.svg" />
          <p class="telemedicine__feats__item__title">
            {{ $t("landing.brokers.telemedicine.feats.patientCentric.title") }}
          </p>
          <p class="telemedicine__feats__item__text">
            {{ $t("landing.brokers.telemedicine.feats.patientCentric.text") }}
          </p>
        </div>
      </div>
    </section>

    <section class="onboard">
      <div class="onboard__left">
        <h1 class="onboard__left__title">{{ $t("landing.employers.onboard.title") }}</h1>
        <p class="onboard__left__text">
          {{ $t("landing.employers.onboard.text") }}
        </p>
        <div class="onboard__left__feats">
          <div class="onboard__left__feats__item">
            <img alt="" class="onboard__left__feats__item__img" src="@/assets/landing/patient-exp.svg" />
            <span class="onboard__left__feats__item__text">{{
              $t("landing.employers.onboard.feats.satisfaction")
            }}</span>
          </div>
          <div class="onboard__left__feats__item">
            <img alt="" class="onboard__left__feats__item__img" src="@/assets/landing/calendar.svg" />
            <span class="onboard__left__feats__item__text">{{ $t("landing.employers.onboard.feats.engagement") }}</span>
          </div>
          <div class="onboard__left__feats__item">
            <img alt="" class="onboard__left__feats__item__img" src="@/assets/landing/metrics.svg" />
            <span class="onboard__left__feats__item__text">{{ $t("landing.employers.onboard.feats.metrics") }} </span>
          </div>
        </div>
      </div>
      <div class="onboard__right">
        <img alt="" class="onboard__right__img" src="@/assets/landing/onboard.jpg" />
      </div>
    </section>

    <section class="feedback">
      <div class="feedback__top">
        <div class="feedback__top__left">
          <img alt="" class="feedback__top__left__img" src="@/assets/landing/heart.svg" />
          <h1 class="feedback__top__left__title">{{ $t("landing.main.feedback.title") }}</h1>
        </div>
        <div class="feedback__top__right">
          <div class="feedback__top__right__left-arrow" @click="previousFeedbackSlide"></div>
          <div class="feedback__top__right__right-arrow" @click="nextFeedbackSlide"></div>
        </div>
      </div>
      <Flickity ref="flickityFeedback" :options="flickityFeedbackOptions" class="feedback__carousel">
        <div class="feedback__slide--wrap">
          <div class="feedback__slide">
            <img alt="" class="feedback__slide__img" src="@/assets/landing/avatar1.png" />
            <div class="feedback__slide__personal-info">
              <p class="feedback__slide__personal-info__name">Brian</p>
              <p class="feedback__slide__personal-info__location">New Orleans LA</p>
            </div>
            <div class="feedback__slide__rating">
              <span class="feedback__slide__rating__number">4.9</span>
              <img alt="" class="feedback__slide__rating__star" src="@/assets/landing/star.svg" />
            </div>
            <p class="feedback__slide__text">
              "I have never experienced such a personalized experienced with a doctor, he listened and suggested to
              follow up with me in a few days for a bad cold. Amazing service."
            </p>
          </div>
        </div>
        <div class="feedback__slide--wrap">
          <div class="feedback__slide">
            <img alt="" class="feedback__slide__img" src="@/assets/landing/avatar2.webp" />
            <div class="feedback__slide__personal-info">
              <p class="feedback__slide__personal-info__name">Kyle</p>
              <p class="feedback__slide__personal-info__location">Long Beach CA</p>
            </div>
            <div class="feedback__slide__rating">
              <span class="feedback__slide__rating__number">4.7</span>
              <img alt="" class="feedback__slide__rating__star" src="@/assets/landing/star.svg" />
            </div>
            <p class="feedback__slide__text">
              "I was out of my blood pressure medications and due to COVID19 and work I was having a hard time making it
              to my PCP, Axis Clinic was able to verify and send a refill of my medication in just a few hours."
            </p>
          </div>
        </div>
        <div class="feedback__slide--wrap">
          <div class="feedback__slide">
            <img alt="" class="feedback__slide__img" src="@/assets/landing/avatar3.webp" />
            <div class="feedback__slide__personal-info">
              <p class="feedback__slide__personal-info__name">Tyler</p>
              <p class="feedback__slide__personal-info__location">San Jose CA</p>
            </div>
            <div class="feedback__slide__rating">
              <span class="feedback__slide__rating__number">5.0</span>
              <img alt="" class="feedback__slide__rating__star" src="@/assets/landing/star.svg" />
            </div>
            <p class="feedback__slide__text">
              "I never thought that speaking to a physician for a medical question could be so simple, I love this
              service."
            </p>
          </div>
        </div>
        <div class="feedback__slide--wrap">
          <div class="feedback__slide">
            <img alt="" class="feedback__slide__img" src="@/assets/landing/avatar4.webp" />
            <div class="feedback__slide__personal-info">
              <p class="feedback__slide__personal-info__name">Lindsey</p>
              <p class="feedback__slide__personal-info__location">Norfolk VA</p>
            </div>
            <div class="feedback__slide__rating">
              <span class="feedback__slide__rating__number">5.0</span>
              <img alt="" class="feedback__slide__rating__star" src="@/assets/landing/star.svg" />
            </div>
            <p class="feedback__slide__text">
              "Having this service for my young children has saved us money, time and worrisome nights! I am truly
              grateful to have this service."
            </p>
          </div>
        </div>

        <div class="feedback__slide--wrap">
          <div class="feedback__slide">
            <img alt="" class="feedback__slide__img" src="@/assets/landing/avatar5.webp" />
            <div class="feedback__slide__personal-info">
              <p class="feedback__slide__personal-info__name">Stephanie</p>
              <p class="feedback__slide__personal-info__location">Memphis TN</p>
            </div>
            <div class="feedback__slide__rating">
              <span class="feedback__slide__rating__number">4.7</span>
              <img alt="" class="feedback__slide__rating__star" src="@/assets/landing/star.svg" />
            </div>
            <p class="feedback__slide__text">
              "Axis.Clinic I can not recommend them enough, great service, they take the time to listen and to explain
              what they are thinking, you speak with the same doctor everytime, really makes you feel better the moment
              you talk to them"
            </p>
          </div>
        </div>
        <div class="feedback__slide--wrap">
          <div class="feedback__slide">
            <img alt="" class="feedback__slide__img" src="@/assets/landing/avatar6.webp" />
            <div class="feedback__slide__personal-info">
              <p class="feedback__slide__personal-info__name">Mayra</p>
              <p class="feedback__slide__personal-info__location">Memphis TN</p>
            </div>
            <div class="feedback__slide__rating">
              <span class="feedback__slide__rating__number">4.8</span>
              <img alt="" class="feedback__slide__rating__star" src="@/assets/landing/star.svg" />
            </div>
            <p class="feedback__slide__text">
              "I was worried about missing work since we have a major project due in just 2 days, I was able to talk to
              my doctor the same day and that night I had my medications for a urinary tract infection"
            </p>
          </div>
        </div>
        <div class="feedback__slide--wrap">
          <div class="feedback__slide">
            <img alt="" class="feedback__slide__img" src="@/assets/landing/avatar7.webp" />
            <div class="feedback__slide__personal-info">
              <p class="feedback__slide__personal-info__name">Katlyn</p>
              <p class="feedback__slide__personal-info__location">Memphis TN</p>
            </div>
            <div class="feedback__slide__rating">
              <span class="feedback__slide__rating__number">5.0</span>
              <img alt="" class="feedback__slide__rating__star" src="@/assets/landing/star.svg" />
            </div>
            <p class="feedback__slide__text">
              "Taking our kids to the doctor's office is always a challenge since my husband and I both work long hours,
              I am so grateful for what they do!, it's a huge relief knowing we have Axis.Clinic to take care of us when
              we need it."
            </p>
          </div>
        </div>
        <div class="feedback__slide--wrap">
          <div class="feedback__slide">
            <img alt="" class="feedback__slide__img" src="@/assets/landing/avatar8.webp" />
            <div class="feedback__slide__personal-info">
              <p class="feedback__slide__personal-info__name">Fernando</p>
              <p class="feedback__slide__personal-info__location">Memphis TN</p>
            </div>
            <div class="feedback__slide__rating">
              <span class="feedback__slide__rating__number">4.9</span>
              <img alt="" class="feedback__slide__rating__star" src="@/assets/landing/star.svg" />
            </div>
            <p class="feedback__slide__text">
              "I am a high risk patients for COVID19 due to my history of ulcerative colitis, I am very worried about
              visiting the ER during the covid pandemic, thanks to Axis.Clinic I have gotten the care I need without
              putting myself at risk."
            </p>
          </div>
        </div>
      </Flickity>
    </section>

    <section class="request">
      <div class="request__text-block">
        <h1 class="request__text-block__title">{{ $t("landing.brokers.request") }}</h1>
        <v-btn class="request__text-block__btn landing-btn" color="primary" dark depressed @click="openContactDialog"
          >{{ $t("landing.requestQuote") }}
        </v-btn>
      </div>
      <img alt="" class="request__img" src="@/assets/landing/brokers-request.jpg" />
    </section>

    <LandingFooter />

    <ContactUsDialog v-if="contactDialog" @close="closeContactDialog" />

    <img v-if="showArrowUp" alt="" class="arrow-up" src="@/assets/landing/arrow-up.svg" @click="scrollToTop" />
  </main>
</template>

<script>
import Flickity from "vue-flickity";

import ContactUsDialog from "./ContactUsDialog.vue";
import LandingFooter from "./LandingFooter.vue";
import LandingHeader from "./LandingHeader.vue";

export default {
  name: "Brokers",
  components: {
    LandingHeader,
    LandingFooter,
    Flickity,
    ContactUsDialog,
  },
  data() {
    return {
      flickityFeedbackOptions: {
        prevNextButtons: false,
        wrapAround: true,
      },
      contactDialog: false,
      showArrowUp: false,
    };
  },
  methods: {
    nextFeedbackSlide() {
      this.$refs.flickityFeedback.next();
    },
    previousFeedbackSlide() {
      this.$refs.flickityFeedback.previous();
    },
    openContactDialog() {
      this.contactDialog = true;
    },
    closeContactDialog() {
      this.contactDialog = false;
    },
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: "auto" });
    },
    onScroll() {
      this.showArrowUp = window.scrollY > 300;
    },
  },
  mounted() {
    window.scrollTo({ top: 0, behavior: "auto" });
    window.addEventListener("scroll", this.onScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
  },
};
</script>

<style lang="scss">
.brokers {
  * {
    font-size: 18px;
  }

  .arrow-up {
    position: fixed;
    bottom: 50px;
    right: 50px;
    cursor: pointer;
    width: 60px;
    height: 60px;
    opacity: 0.5;
    z-index: 9;

    @media (max-width: 768px) {
      width: 50px;
      height: 50px;
    }

    &:hover {
      opacity: 1;
    }
  }

  .flickity-page-dots {
    text-align: left;
    padding-left: 0 !important;

    @media (max-width: 480px) {
      margin-left: 40px;
    }
  }

  .flickity-page-dots .dot {
    width: 15px;
    height: 15px;
    transition: 0.3s ease;
  }

  .flickity-page-dots .dot.is-selected {
    background: $primary;
    width: 30px;
    border-radius: 15px;
  }

  .top-screen {
    background-color: $primarylight1;
    display: flex;
    justify-content: space-between;
    padding: 150px 0;
    position: relative;

    @media (max-width: 768px) {
      padding: 60px 0;
    }

    &:before {
      content: "";
      position: absolute;
      bottom: 100%;
      left: 0;
      width: 100%;
      height: 200px;
      background-color: $primarylight1;
      z-index: 2;
    }

    &__bg-logo {
      position: absolute;
      top: 50px;
      left: 50%;
      transform: translateX(-50%);
      width: 370px;
      height: 370px;

      @media (max-width: 768px) {
        width: 250px;
        height: 250px;
      }
    }

    &__left {
      margin: 0 40px;
      width: 50%;

      @media (max-width: 580px) {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      &__title {
        font-size: 66px;
        line-height: 76px;
        max-width: 550px;
        position: relative;
        z-index: 2;

        @media (max-width: 1024px) {
          font-size: 52px;
          line-height: 60px;
        }

        @media (max-width: 768px) {
          font-size: 40px;
          line-height: 45px;
          text-align: center;
        }
        @media (max-width: 480px) {
          font-size: 32px;
          line-height: 35px;
        }

        strong {
          font-size: 66px;
          position: relative;
          z-index: 2;

          @media (max-width: 1024px) {
            font-size: 52px;
            line-height: 60px;
          }
          @media (max-width: 768px) {
            font-size: 40px;
            line-height: 45px;
          }
          @media (max-width: 480px) {
            font-size: 32px;
            line-height: 35px;
          }
        }
      }

      &__text {
        margin: 18px 0 32px;
        max-width: 550px;
        position: relative;
        z-index: 2;
      }
    }

    &__right {
      width: 50%;
      z-index: 1;
      margin-left: 100px;
      border-radius: 10px;

      @media (max-width: 768px) {
        margin-left: 20px;
      }
      @media (max-width: 580px) {
        display: none;
      }
    }
  }

  .quality {
    display: flex;
    padding: 180px 0 300px;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.32), rgba(255, 255, 255, 0.32)), $primarylight1;

    @media (max-width: 768px) {
      padding: 60px 0;
    }

    &__left {
      margin-left: 40px;
      position: relative;
      width: 45%;

      @media (max-width: 1024px) {
        width: 30%;
      }
      @media (max-width: 768px) {
        display: none;
      }

      &__img {
        position: absolute;
        bottom: -200px;
        left: 0;
        width: auto;
        height: auto;
        max-height: 900px;
        border-radius: 10px;

        @media (max-width: 1200px) {
          bottom: initial;
          top: -30%;
          width: 100%;
        }
        @media (max-width: 1024px) {
          top: 0;
        }
      }
    }

    &__right {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      max-width: 550px;
      padding: 0 40px;
      box-sizing: content-box;

      @media (max-width: 768px) {
        max-width: 100%;
        align-items: center;
      }

      &__title {
        font-size: 52px;
        line-height: 62px;
        letter-spacing: -1px;

        @media (max-width: 1024px) {
          font-size: 40px;
          line-height: 50px;
        }
        @media (max-width: 580px) {
          font-size: 28px;
          line-height: 1;
        }
      }

      &__text {
        margin: 20px 0 40px;

        @media (max-width: 580px) {
          font-size: 14px;
        }
      }
    }
  }

  .telemedicine {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 150px 40px 140px;

    @media (max-width: 1024px) {
      padding: 100px 40px;
    }
    @media (max-width: 768px) {
      padding: 60px 20px;
    }

    &__title {
      font-size: 52px;
      line-height: 62px;
      text-align: center;
      letter-spacing: -0.827586px;
      max-width: 660px;

      @media (max-width: 768px) {
        font-size: 40px;
      }
      @media (max-width: 480px) {
        font-size: 28px;
        line-height: 1;
      }
    }

    &__text {
      margin: 20px 0 40px;
    }

    &__feats {
      display: flex;
      max-width: 1100px;
      width: 100%;
      flex-wrap: wrap;
      justify-content: space-between;

      @media (max-width: 1024px) {
        flex-direction: column;
        align-items: center;
      }

      &__item {
        display: flex;
        flex-direction: column;
        max-width: 330px;
        align-items: center;

        @media (max-width: 1024px) {
          margin-bottom: 20px;
        }

        &__text {
          text-align: center;
        }

        &__title {
          margin: 16px 0 10px;
          font-weight: bold;
          font-size: 24px;
          text-align: center;
          @media (max-width: 768px) {
            font-size: 20px;
          }
        }

        &__img {
          height: 50px;
        }
      }
    }
  }

  .onboard {
    display: flex;
    padding: 130px 0;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.32), rgba(255, 255, 255, 0.32)), $primarylight1;

    @media (max-width: 1024px) {
      padding: 100px 0;
    }
    @media (max-width: 768px) {
      padding: 60px 0;
    }

    &__left {
      margin-left: 40px;
      margin-right: 100px;
      display: flex;
      flex-direction: column;

      @media (max-width: 768px) {
        margin-right: 40px;
      }

      &__title {
        font-size: 52px;
        line-height: 62px;
        letter-spacing: -1px;

        @media (max-width: 1024px) {
          font-size: 40px;
          line-height: 50px;
        }
        @media (max-width: 480px) {
          font-size: 28px;
          line-height: 1;
        }
      }

      &__text {
        margin: 20px 0 40px;
      }

      &__feats {
        display: flex;
        flex-direction: column;

        &__item {
          display: flex;
          align-items: center;
          margin-bottom: 24px;

          &__text {
            font-size: 24px;
            margin-left: 20px;
            font-weight: bold;

            @media (max-width: 480px) {
              font-size: 20px;
            }
          }

          &__img {
            width: 42px;
          }
        }
      }
    }

    &__right {
      width: 55%;

      @media (max-width: 1200px) {
        width: 45%;
      }
      @media (max-width: 768px) {
        display: none;
      }
    }
  }

  .feedback {
    padding: 100px 0 140px;
    position: relative;

    @media (max-width: 480px) {
      padding: 70px 0;
    }

    &__carousel {
      padding: 20px 40px;

      @media (max-width: 480px) {
        padding: 20px 0 70px;
      }
    }

    &__top {
      padding: 0 40px;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;

      @media (max-width: 768px) {
        flex-direction: column;
        justify-content: flex-start;
      }

      &__left {
        &__title {
          font-size: 52px;
          line-height: 62px;
          letter-spacing: -1px;
          margin-top: 35px;
          max-width: 580px;

          @media (max-width: 1150px) {
            font-size: 40px;
          }
          @media (max-width: 768px) {
            margin-bottom: 30px;
          }
          @media (max-width: 480px) {
            font-size: 28px;
            line-height: 1;
          }
        }

        &__img {
          @media (max-width: 1150px) {
            width: 60px;
            height: 60px;
          }
          @media (max-width: 480px) {
            width: 40px;
            height: 40px;
          }
        }
      }

      &__right {
        display: flex;
        z-index: 4;
        @media (max-width: 768px) {
          align-self: flex-end;
        }
        @media (max-width: 480px) {
          position: absolute;
          bottom: 80px;
        }

        &__left-arrow {
          width: 53px;
          height: 53px;
          border-radius: 50%;
          border: 2px solid $primary;
          position: relative;
          margin-right: 20px;
          cursor: pointer;
          transition: 0.2s ease;

          &:before {
            content: "";
            position: absolute;
            left: 50%;
            top: calc(50% - 4px);
            transform: translate(-50%) rotate(-45deg);
            width: 10px;
            height: 2px;
            background-color: $primary;
            transition: 0.2s ease;
          }

          &:after {
            content: "";
            position: absolute;
            left: 50%;
            bottom: calc(50% - 4px);
            transform: translate(-50%) rotate(45deg);
            width: 10px;
            height: 2px;
            background-color: $primary;
            transition: 0.2s ease;
          }

          &:hover {
            background-color: $primary;

            &:before,
            &:after {
              background-color: #fff;
            }
          }
        }

        &__right-arrow {
          width: 53px;
          height: 53px;
          border-radius: 50%;
          border: 2px solid $primary;
          position: relative;
          margin-right: 20px;
          background-color: $primary;
          cursor: pointer;
          opacity: 0.8;
          transition: 0.2s ease;

          &:before {
            content: "";
            position: absolute;
            left: 50%;
            top: calc(50% - 4px);
            transform: translate(-50%) rotate(45deg);
            width: 10px;
            height: 2px;
            background-color: #fff;
            transition: 0.2s ease;
          }

          &:after {
            content: "";
            position: absolute;
            left: 50%;
            bottom: calc(50% - 4px);
            transform: translate(-50%) rotate(-45deg);
            width: 10px;
            height: 2px;
            background-color: #fff;
            transition: 0.2s ease;
          }

          &:hover {
            opacity: 1;
          }
        }
      }
    }

    &__slide {
      width: 430px;
      max-height: 230px;
      display: grid;
      grid-template-columns: 50px 1fr 50px;
      grid-template-rows: 1fr 1fr;
      padding: 30px;
      box-shadow: 0 2px 26px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      margin: 0 16px;
      align-items: center;
      row-gap: 16px;

      @media (max-width: 480px) {
        width: 360px;
      }

      &--wrap {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 270px;
      }

      &__img {
        height: 50px;
        width: 50px;
        border-radius: 50%;
      }

      &__personal-info {
        margin-left: 20px;

        &__name {
          margin-bottom: 0 !important;
          font-weight: bold;
        }

        &__location {
          margin-bottom: 0 !important;
          font-size: 14px;
        }
      }

      &__rating {
        display: flex;
        justify-content: space-between;
      }

      &__text {
        grid-column: 1 / end;
        font-size: 16px;

        @media (max-width: 480px) {
          font-size: 14px;
        }
      }
    }
  }

  .request {
    display: flex;
    background-color: #f6f6f6;

    &__img {
      width: 50%;
      @media (max-width: 580px) {
        display: none;
      }
    }

    &__text-block {
      margin: 40px 150px 40px 40px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      width: 50%;

      @media (max-width: 900px) {
        margin: 40px;
      }
      @media (max-width: 580px) {
        width: 100%;
        align-items: center;
      }

      &__title {
        font-size: 52px;
        line-height: 62px;
        letter-spacing: -1px;
        margin-bottom: 50px;

        @media (max-width: 1150px) {
          font-size: 40px;
          line-height: 1;
        }
        @media (max-width: 768px) {
          font-size: 28px;
        }
        @media (max-width: 580px) {
          text-align: center;
          margin-bottom: 30px;
        }
        @media (max-width: 380px) {
          font-size: 24px;
        }
      }
    }
  }
}
</style>
